 
.mainLogo svg {
  fill: white
}

.hover .letter1 {
  animation: letter1 .3s linear;
}
.hover .letter2 {
  animation: letter2 .3s linear;
  animation-delay: .1s;
}
.hover .letter3 {
  animation: letter3 .3s linear;
  animation-delay: .2s;
}
.hover .letter4 {
  animation: letter4 .3s linear;
  animation-delay: .3s;
}
.hover .letter5 {
  animation: letter5 .3s linear;
  animation-delay: .4s;
}


@keyframes letter1 {
  0% { transform: translatey(0px); }
  20% { fill: #FFA22B; transform: translatey(-1px); }
  40% { fill: #FFA22B; transform: translatey(-1px); }
  100% { transform: translatey(0px); }
}

@keyframes letter2 {
  0% { transform: translatey(0px); }
  20% { fill: #8A4BBB; transform: translatey(-1px); }
  40% { fill: #8A4BBB; transform: translatey(-1px); }
  100% { transform: translatey(0px); }
}

@keyframes letter3 {
  0% { transform: translatey(0px); }
  20% { fill: #106B43; transform: translatey(-1px); }
  40% { fill: #106B43; transform: translatey(-1px); }
  100% { transform: translatey(0px); }
}

@keyframes letter4 {
  0% { transform: translatey(0px); }
  20% { fill: #FFA22B; transform: translatey(-1px); }
  40% { fill: #FFA22B; transform: translatey(-1px); }
  100% { transform: translatey(0px); }
}

@keyframes letter5 {
  0% { transform: translatey(0px); }
  20% { fill: #8A4BBB; transform: translatey(-1px); }
  40% { fill: #8A4BBB; transform: translatey(-1px); }
  100% { transform: translatey(0px); }
}
