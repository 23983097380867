.news a {
  color: #FFA22B
}

.news p{
  margin-bottom: 0;
  margin-top: 1.4em;
  font-weight: 300;

}
.inventory p{
  margin-bottom: .8em;
 font-weight: 300;
 font-size: 1em;
}
.inventory strong
{
  padding-top: 1.2em;
}

.news {
  line-height: calc(1.5em + .2vw)
}

.picked a{ 
  color: #FFA22B;
     text-decoration: underline;

}
.picked a:hover{
  color: #fff;
  text-decoration: underline;
} 